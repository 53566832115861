import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { openPopupWidget } from "react-calendly"

import HeaderFlat from "../components/HeaderFlat"
import BookStrategyBtnPrimary from "../components/BookStrategyBtnPrimary"
import FooterGlobal from "../components/FooterGlobal"
import SEO from "../components/seo"
import Layout from "../components/layout"
import MainNav from "../components/MainNav"

import { FaPlus } from "react-icons/fa"
import { FaMinus } from "react-icons/fa"
import { FaAngleDoubleRight } from "react-icons/fa"
import { FaCheckCircle } from "react-icons/fa"

export const getData = graphql`
  {
    wpgraphql_greatweb {
      pageBy(uri: "web-design-philippines") {
        pageSiteMetaTitle_acf {
          sitemetatitle
        }
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
      }
      homePageBy: pageBy(uri: "home") {
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              countdownData
              companyAddress
              companyContactNumber
              companyOverview
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
          }
        }
      }
    }
  }
`

const WebDesign = ({ data }) => {
  const {
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaTitle_acf: { sitemetatitle },
        pageSiteMetaDescription_acf: { siteMetaDescription },
      },
      homePageBy: {
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              countdownData,
              companyAddress,
              companyContactNumber,
              companyOverview,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
          },
        },
      },
    },
  } = data

  const [show, setShow] = useState(0)

  const handleAccordion = id => {
    if (show === id) {
      setShow(0)
    } else {
      setShow(id)
    }
  }

  const handleCalendly = () => {
    const url = "https://calendly.com/rayjenscode/15"
    openPopupWidget({ url })
  }

  return (
    <>
      <Layout
        announcement={!announcement ? "" : announcement}
        countdownData={!countdownData ? "" : countdownData}
      >
        <SEO title={sitemetatitle} description={siteMetaDescription} />
        <MainNav active="services" />
        <HeaderFlat
        // siteTitle={data.site.siteMetadata?.title || `GreatWeb`}

        // background={heroBG}
        // contact={contactNumber}
        />

        <section className="webdesign-philippines">
          <div className="webdesign-philippines__header container">
            <div className="webdesign-philippines__header__left">
              <p className="webdesign-philippines__header__left--note">
                WEB DESIGN PHILIPPINES
              </p>
              <h1 className="webdesign-philippines__header__left--heading">
                Get a high-performance business website that generates revenue
              </h1>
              <h2 className="webdesign-philippines__header__left--subheading">
                We use proven digital marketing strategies to{" "}
                <span className="webdesign-philippines__header__left--span">
                  drive more, increase phone calls, convert your website
                  visitors{" "}
                </span>
                and increase high revenue.
              </h2>

              <button
                className="mt-3 btn btn-primary btn-large text-normal"
                onClick={handleCalendly}
              >
                Speak with me about a new website
              </button>
            </div>
            <ul className="webdesign-philippines__partners--list">
              <li className="webdesign-philippines__partners--item">
                <img
                  src={require("../images/montregal-logo.png")}
                  alt="Great Web Partners"
                  className="webdesign-philippines__partnersimg"
                />
              </li>
              <li className="webdesign-philippines__partners--item">
                <img
                  src={require("../images/2eas-logopartner.png")}
                  alt="Great Web Partners"
                  className="webdesign-philippines__partners--img"
                />
              </li>
              <li className="webdesign-philippines__partners--item">
                <img
                  src={require("../images/hope-logo.png")}
                  alt="Great Web Partners"
                  className="webdesign-philippines__partners--img"
                />
              </li>
              <li className="webdesign-philippines__partners--item">
                <img
                  src={require("../images/CRM-logo.png")}
                  alt="Great Web Partners"
                  className="webdesign-philippines__partners--img"
                />
              </li>
              <li className="webdesign-philippines__partners--item">
                <img
                  src={require("../images/arthur-logopartner.png")}
                  alt="Great Web Partners"
                  className="webdesign-philippines__partners--img"
                />
              </li>
              <li className="webdesign-philippines__partners--item">
                <img
                  src={require("../images/aim1-logo.png")}
                  alt="Great Web Partners"
                  className="webdesign-philippines__partners--img"
                />
              </li>
            </ul>
            <div className="webdesign-philippines__showcaseWrapper">
              <img
                src={require("../images/webdesign-portfolios.png")}
                alt="Great Web Design"
                className="webdesign-philippines__img"
              />
            </div>
          </div>

          <div className="webdesign-philippines__cover container">
            <h2 className="webdesign-philippines__cover--heading">
              How we optimized our client's website to triple their sales
              without spending a cent of Ads?
            </h2>
            <h2 className="text-center">(Client's Actual Result)</h2>

            <div className="webdesign-philippines__cover--imgWrapper">
              <img
                src={require("../images/greatweb-results.jpg")}
                alt="Great Web Client Result"
                className="webdesign-philippines__cover--img"
              />
            </div>

            <BookStrategyBtnPrimary />
          </div>

          <div className="webdesign-philippines__portfolio container">
            <h2 className="webdesign-philippines__portfolio--heading">
              We've built websites for awesome business in the Philippines...
            </h2>
            <p className="webdesign-philippines__portfolio--subheading">
              (Don't worry, your's will be unique to your business)
            </p>

            <ul className="webdesign-philippines__portfolio--list">
              <li className="webdesign-philippines__portfolio--item">
                <div className="webdesign-philippines__portfolio--imgWrapper">
                  <img
                    src={require("../images/portfolio/montregal.jpg")}
                    alt="Great Web Portfolio"
                    className="webdesign-philippines__portfolio--img"
                  />
                </div>
              </li>
              <li className="webdesign-philippines__portfolio--item">
                <div className="webdesign-philippines__portfolio--imgWrapper">
                  <img
                    src={require("../images/portfolio/2eas.jpg")}
                    alt="Great Web Portfolio"
                    className="webdesign-philippines__portfolio--img"
                  />
                </div>
              </li>
              <li className="webdesign-philippines__portfolio--item">
                <div className="webdesign-philippines__portfolio--imgWrapper">
                  <img
                    src={require("../images/portfolio/aim1.jpg")}
                    alt="Great Web Portfolio"
                    className="webdesign-philippines__portfolio--img"
                  />
                </div>
              </li>
              <li className="webdesign-philippines__portfolio--item">
                <div className="webdesign-philippines__portfolio--imgWrapper">
                  <img
                    src={require("../images/portfolio/aurhur.jpg")}
                    alt="Great Web Portfolio"
                    className="webdesign-philippines__portfolio--img"
                  />
                </div>
              </li>
              <li className="webdesign-philippines__portfolio--item">
                <div className="webdesign-philippines__portfolio--imgWrapper">
                  <img
                    src={require("../images/portfolio/crm.jpg")}
                    alt="Great Web Portfolio"
                    className="webdesign-philippines__portfolio--img"
                  />
                </div>
              </li>
              <li className="webdesign-philippines__portfolio--item">
                <div className="webdesign-philippines__portfolio--imgWrapper">
                  <img
                    src={require("../images/portfolio/hope.jpg")}
                    alt="Great Web Portfolio"
                    className="webdesign-philippines__portfolio--img"
                  />
                </div>
              </li>
            </ul>

            <h3 className="webdesign-philippines__portfolio--closing">
              <span className="webdesign-philippines__portfolio--closing--span">
                WARNING:
              </span>{" "}
              You Need Someone That Knows More Than Just Design To Create Your
              Online Sales Machine...
            </h3>
            <h3 className="webdesign-philippines__portfolio--subclosing">
              I Have Experience In Design, Sales, Marketing, Funnels and More!
            </h3>
            <p className="webdesign-philippines__portfolio--guarantee">
              (I Guarantee Results - Or Your Money Back)
            </p>

            <div className="webdesign-philippines__qualify">
              <h3 className="webdesign-philippines__qualify--heading">
                If You...
              </h3>
              <ul className="webdesign-philippines__qualify--list">
                <li className="webdesign-philippines__qualify--item">
                  <FaAngleDoubleRight className="webdesign-philippines__qualify--item--icon" />

                  <p className="webdesign-philippines__qualify--text">
                    Have an old website that doesn't generate customers.
                  </p>
                </li>

                <li className="webdesign-philippines__qualify--item">
                  <FaAngleDoubleRight className="webdesign-philippines__qualify--item--icon" />
                  <p className="webdesign-philippines__qualify--text">
                    Need a brand new website.
                  </p>
                </li>
                <li className="webdesign-philippines__qualify--item">
                  <FaAngleDoubleRight className="webdesign-philippines__qualify--item--icon" />
                  <p className="webdesign-philippines__qualify--text">
                    Don't have time to learn web design or digital marketing on
                    your own.
                  </p>
                </li>
                <li className="webdesign-philippines__qualify--item">
                  <FaAngleDoubleRight className="webdesign-philippines__qualify--item--icon" />
                  <p className="webdesign-philippines__qualify--text">
                    Need more clients or customers.
                  </p>
                </li>
                <li className="webdesign-philippines__qualify--item">
                  <FaAngleDoubleRight className="webdesign-philippines__qualify--item--icon" />
                  <p className="webdesign-philippines__qualify--text">
                    Want to simplify your life as a business owner.
                  </p>
                </li>
                <li className="webdesign-philippines__qualify--item">
                  <FaAngleDoubleRight className="webdesign-philippines__qualify--item--icon" />
                  <p className="webdesign-philippines__qualify--text">
                    Want more free time to do what you love.
                  </p>
                </li>
                <li className="webdesign-philippines__qualify--item">
                  <FaAngleDoubleRight className="webdesign-philippines__qualify--item--icon" />
                  <p className="webdesign-philippines__qualify--text">
                    Have wasted money on a website or marketing before with no
                    results.
                  </p>
                </li>
              </ul>
            </div>

            <BookStrategyBtnPrimary />
          </div>

          <div className="webdesign-philippines__faq container">
            <h3 className="webdesign-philippines__faq--heading">
              Frequently Asked Questions
            </h3>
            <ul className="webdesign-philippines__faq--list">
              <li className="webdesign-philippines__faq--item">
                <h4
                  className="webdesign-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(1)}
                >
                  <div>
                    {show === 1 ? (
                      <FaMinus className="webdesign-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="webdesign-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="webdesign-philippines__faq--item--question--span">
                    What types of businesses does this work for?
                  </span>
                </h4>
                {/* {show === 1 && ( */}
                <div
                  className={`${
                    show === 1
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="webdesign-philippines__faq--item--description">
                    I work primarily with service-based businesses that operate
                    out of a physical location or within a geographical area.
                    This includes (but is not limited to):
                  </p>

                  <ul className="webdesign-philippines__faq--item--description--list">
                    <li className="webdesign-philippines__faq--item--description--item">
                      <FaCheckCircle className="webdesign-philippines__faq--item--description--item--icon" />
                      <span>Law Firms</span>
                    </li>
                    <li className="webdesign-philippines__faq--item--description--item">
                      <FaCheckCircle className="webdesign-philippines__faq--item--description--item--icon" />
                      Dental Clinics
                    </li>
                    <li className="webdesign-philippines__faq--item--description--item">
                      <FaCheckCircle className="webdesign-philippines__faq--item--description--item--icon" />
                      Real Estate
                    </li>
                    <li className="webdesign-philippines__faq--item--description--item">
                      <FaCheckCircle className="webdesign-philippines__faq--item--description--item--icon" />
                      Hotels & Resorts
                    </li>
                    <li className="webdesign-philippines__faq--item--description--item">
                      <FaCheckCircle className="webdesign-philippines__faq--item--description--item--icon" />
                      Construction
                    </li>
                    <li className="webdesign-philippines__faq--item--description--item">
                      <FaCheckCircle className="webdesign-philippines__faq--item--description--item--icon" />
                      Schools & Universities
                    </li>
                    <li className="webdesign-philippines__faq--item--description--item">
                      <FaCheckCircle className="webdesign-philippines__faq--item--description--item--icon" />
                      + More
                    </li>
                  </ul>
                </div>
                {/* )} */}
              </li>
              <li className="webdesign-philippines__faq--item">
                <h4
                  className="webdesign-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(2)}
                >
                  <div>
                    {show === 2 ? (
                      <FaMinus className="webdesign-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="webdesign-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="webdesign-philippines__faq--item--question--span">
                    What if I don't like the look of my new website?
                  </span>
                </h4>
                {/* {show === 2 && ( */}
                <div
                  className={`${
                    show === 2
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="webdesign-philippines__faq--item--description">
                    With each build I have two goals:
                  </p>
                  <p className="webdesign-philippines__faq--item--description">
                    1.{" "}
                    <span className="webdesign-philippines__faq--item--description--span">
                      Looks.
                    </span>{" "}
                    To build a site that looks visually appealing to you and
                    your site visitors.
                  </p>
                  <p className="webdesign-philippines__faq--item--description">
                    2.{" "}
                    <span className="webdesign-philippines__faq--item--description--span">
                      Functionality.
                    </span>{" "}
                    To build a site that converts visitors into leads for your
                    business.
                  </p>
                  <p className="webdesign-philippines__faq--item--description">
                    To ensure that both of these goals are accomplished, I offer
                    unlimited site revisions to make sure we get the site just
                    right. I also allow you to watch the site progress live so
                    you can make suggestions along the way.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="webdesign-philippines__faq--item">
                <h4
                  className="webdesign-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(3)}
                >
                  <div>
                    {show === 3 ? (
                      <FaMinus className="webdesign-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="webdesign-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="webdesign-philippines__faq--item--question--span">
                    {" "}
                    Will I have to pay you when I want to update my site?
                  </span>
                </h4>
                {/* {show === 3 && ( */}
                <div
                  className={`${
                    show === 3
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="webdesign-philippines__faq--item--description">
                    <span className="webdesign-philippines__faq--item--description--span">
                      No no no.
                    </span>{" "}
                    This is a classic scam used by web design companies. They
                    offer a discounted site (at first) that locks you in to
                    hefty fees when you need things updated or changed. I
                    believe you should have complete control to change and
                    manage your website as you please.
                  </p>
                  <p className="webdesign-philippines__faq--item--description">
                    For this reason, I offer a powerful CMS software that allows
                    you to{" "}
                    <span className="webdesign-philippines__faq--item--description--span">
                      easily make changes to your website from a simple
                      dashboard.
                    </span>
                  </p>
                  <p className="webdesign-philippines__faq--item--description">
                    No more waiting days (or even weeks) for your web designer
                    to make simple updates that cost you hundreds of dollars.
                    You are in complete control.
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="webdesign-philippines__faq--item">
                <h4
                  className="webdesign-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(4)}
                >
                  <div>
                    {show === 4 ? (
                      <FaMinus className="webdesign-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="webdesign-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="webdesign-philippines__faq--item--question--span">
                    How much does it cost?
                  </span>
                </h4>
                {/* {show === 4 && ( */}
                <div
                  className={`${
                    show === 4
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="webdesign-philippines__faq--item--description">
                    To make things easy, I help you customize a website package
                    to fit your budget and needs. Whether you need a new website
                    and SEO or just bits and pieces of both, we can make it
                    happen.
                  </p>
                  <p className="webdesign-philippines__faq--item--description">
                    To see pricing and talk about a package specific for your
                    business,{" "}
                    <Link
                      to="/contact"
                      className="webdesign-philippines__faq--item--description--span"
                    >
                      click here to contact me about pricing.
                    </Link>
                  </p>
                </div>
                {/* )} */}
              </li>
              <li className="webdesign-philippines__faq--item">
                <h4
                  className="webdesign-philippines__faq--item--question"
                  onPointerDown={() => handleAccordion(5)}
                >
                  <div>
                    {show === 5 ? (
                      <FaMinus className="webdesign-philippines__faq--item--question--icon" />
                    ) : (
                      <FaPlus className="webdesign-philippines__faq--item--question--icon" />
                    )}
                  </div>
                  <span className="webdesign-philippines__faq--item--question--span">
                    Does my business need a website?
                  </span>
                </h4>
                {/* {show === 5 && ( */}
                <div
                  className={`${
                    show === 5
                      ? "google-reviews__faq--item--descriptionWrapper--active"
                      : "google-reviews__faq--item--descriptionWrapper"
                  }`}
                >
                  <p className="webdesign-philippines__faq--item--description">
                    While the results will vary depending on your business,
                    those that utilize one of my strategy calls typically see
                    results almost immediately. Most are able to see a notable
                    increase in traffic, website inquiries, and direct calls to
                    their business.
                  </p>
                  <p className="webdesign-philippines__faq--item--description">
                    I often run into business owners that feel they don't need a
                    website, claiming that their business "has been just fine
                    without one so far". While this can be true in some cases,
                    the need to establish your business online is becoming less
                    of a luxury and more of a necessity.{" "}
                    <span className="webdesign-philippines__faq--item--description--span">
                      As of 2019, over 90% of consumers check online before
                      making a purchase.
                    </span>{" "}
                    If you are relying solely on referrals and repeat customers
                    to support your business, you could be missing out on
                    massive amounts of untapped revenue.
                  </p>
                </div>
                {/* )} */}
              </li>
            </ul>
            <div className="webdesign-philippines__ready">
              <h4 className="webdesign-philippines__ready--heading">
                Ready to Get Started?
              </h4>
              <p className="webdesign-philippines__ready--subheading">
                If you want to learn more about how I can help grow your
                business, click below to schedule a free growth call. If you are
                ready to start right away,{" "}
                <Link
                  className="webdesign-philippines__ready--link"
                  to="/contact"
                >
                  click here to contact me.
                </Link>
              </p>

              {/* <button
                className="mt-3 btn btn-primary btn-large text-normal"
                onClick={handleCalendly}
              >
                Schedule Call Now - It's Free
              </button> */}

              <BookStrategyBtnPrimary />
            </div>
          </div>
        </section>

        <FooterGlobal
          companyAddress={companyAddress}
          companyContactNumber={companyContactNumber}
          companyOverview={companyOverview}
          facebookLink={facebookLink}
          linkedinLink={linkedinLink}
          supportEmail={supportEmail}
          twiiterLink={twiiterLink}
        />
      </Layout>
    </>
  )
}

export default WebDesign
